import React from 'react'
import { Layout} from '@catamaran/components'

export default function FourOhFour() {
  return (
    <Layout>
      <div>404!</div>
    </Layout>
  )
}
